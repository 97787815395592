import axios from 'axios';
import { getRSA } from '@/utils/RSA_util';
// import { MessageBox, Message } from 'element-ui';
// import routerVue from '@/router/index';
let queryToken = '';
if (sessionStorage.getItem('token')) {
  queryToken = getRSA(sessionStorage.getItem('token'));
}
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: '/api',
  timeout: 30000,
  headers: {
    'X-Token': queryToken
  }
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    config.headers['X-Token'] = queryToken;
    return config;
  }, error => {
    return Promise.reject(error);
  }
)

// 响应拦截器
service.interceptors.response.use(response => {
  const res = response.data;
  // if (res.code === 401) {
  //   MessageBox.confirm('提示账号已经在其他地方登录，如需使用，请重新登录', '确认登出', {
  //     confirmButtonText: '重新登入',
  //     cancelButtonText: '取消',
  //     type: 'warning'
  //   }).then(() => {
  //     routerVue.replace({path: '/login'});
  //   });
  // } else
  if (res.code !== 200) {
    console.error('业务异常: ', res);
    return Promise.reject(res);
  }

  return res;
}, error => {
  return Promise.reject(error);
}
);

export default service;
